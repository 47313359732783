<template>
  <div class="productContent">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack()">企业基本信息</div>
<div v-if="page">
    <p class="title">企业基本信息</p>
    <div class="card">
        <div class="catalog">
            <p>企业名称</p>
            <p>{{editEnterInfo.enterName}}</p>
        </div>
        <div class="catalog">
            <p>企业性质</p>
            <div class="select">
                <select v-model="editEnterInfo.enterProperty">
                    <option value="0">企业法人</option>
                    <option value="1">企业非法人</option>
                    <option value="2">事业单位</option>
                    <option value="3">个体户</option>
                    <option value="4">其他</option>
                </select>
            </div>           
        </div>
        <div class="catalog">
            <p>所属省</p>
            <p>{{editEnterInfo.provinceName}}</p>
        </div>   
        <div class="catalog">
            <p>所属市</p>
            <div class="select">
            <select v-model="editEnterInfo.cityCode">
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>所属区<span class="key">*</span></p>
            <div class="select">
            <select v-model="editEnterInfo.districtCode">
                <option v-for="item in district" :key="item.cityCode" :value="item.cityCode">{{item.cityName}}</option>
           </select>
            </div>
        </div>   
        <div class="catalog">
            <p>成立日期<span class="key">*</span></p>
            <div class="select">
            <input type="date" v-model="editEnterInfo.foundDate">
            </div>
        </div>
        <div class="catalog">
            <p>人数<span class="key">*</span></p>
            <input type="number" v-model="editEnterInfo.peopleCnt">
        </div>
        <div class="catalog">
            <p>规模<span class="key">*</span></p>
            <div class="select">
            <select v-model="editEnterInfo.scale">
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>所属行业<span class="key">*</span></p>
            <div class="select">
            <select v-model="editEnterInfo.involveIndustry">
                <option value="A">农、林、牧、渔业</option>
                <option value="B">采矿业</option>
                <option value="C">制造业</option>
                <option value="D">电力、燃气及水的生产供应业</option>
                <option value="E">建筑业</option>
                <option value="F">交通运输、仓储环境和邮政业</option>
                <option value="G">信息传输、计算机服务业</option>
                <option value="H">批发和零售业</option>
                <option value="I">住宿和餐饮业</option>
                <option value="J">金融业</option>
                <option value="K">房地产业</option>
                <option value="L">租赁和商务服务业</option>
                <option value="M">科学研究、技术服务和地质勘查业</option>
                <option value="N">水利、环境和公共设施管理</option>
                <option value="O">居民服务和其他服务业</option>
                <option value="P">教育业</option>
                <option value="Q">卫生、社会保障和社会福利业</option>
                <option value="R">文化、教育和娱乐业</option>
                <option value="S">公共管理和社会组织</option>
                <option value="T">其他行业</option>
           </select>
            </div>
        </div>   
        <div class="catalog">
            <p style="height: 15vw">主营业务</p>
            <textarea v-model="editEnterInfo.mainBusiness"></textarea>
        </div>
        <div class="catalog">
            <p>法定代表人<span class="key">*</span></p>
            <input type="text" v-model="editEnterInfo.legalName">
        </div>
        <div class="catalog">
            <p>法定代表人证件类型<span class="key">*</span></p>
            <div class="select">
            <select v-model="editEnterInfo.legalCertiType">
                <option value="0">身份证</option>
                <option value="1">护照</option>
                <option value="2">港澳台居民通行证</option>
                <option value="3">港澳台居民居住证</option>
                <option value="4">其他合法身份证件</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>法定代表人证件号码<span class="key">*</span></p>
            <input type="text" v-model="editEnterInfo.legalCertiNum">
        </div>
         <div class="catalog">
            <p>总经理</p>
            <input type="text" v-model="editEnterInfo.generalManagerName" placeholder="输入总经理姓名">
        </div>   
        <div class="catalog">
            <p>总经理电话号码</p>
            <input type="text" v-model="editEnterInfo.generalManagerTelNum" placeholder="输入总经理电话号码">
        </div>
        <div class="catalog">
            <p>财务总监</p>
            <input type="text" v-model="editEnterInfo.financialOfficerName" placeholder="输入财务总监姓名">
        </div>   
        <div class="catalog">
            <p>财务总监电话号码</p>
            <input type="text" v-model="editEnterInfo.financialOfficerTelNum" placeholder="输入财务总监电话号码">
        </div>
        <div class="catalog">
            <p>联系人</p>
            <input type="text" v-model="editEnterInfo.contactsName" placeholder="输入联系人姓名">
        </div>
        <div class="catalog">
            <p>联系人手机号码<span class="key">*</span></p>
            <input type="text" v-model="editEnterInfo.contactsPhone" placeholder="输入联系人手机号码">
        </div>
        <div class="catalog">
            <p>联系人电子邮箱</p>
            <input type="text" v-model="editEnterInfo.contactsEmail" placeholder="输入联系人电子邮箱">
        </div>   
        <div class="catalog">
            <p>贷款需求默认联系人姓名</p>
            <input type="text" v-model="editEnterInfo.creditDefaultContactsName" placeholder="输入贷款需求默认联系人姓名">
        </div>
        <div class="catalog">
            <p>贷款需求默认联系人电话号码</p>
            <input type="text" v-model="editEnterInfo.creditDefaultContactsPhone" placeholder="输入贷款需求默认联系人电话号码">
        </div>
    </div>
    <p class="title">企业登记信息</p>
    <div class="card">
        <div class="catalog">
            <p>同一社会信用代码<span class="key">*</span></p>
            <input type="text" v-model="editEnterInfo.uniscid">
        </div>
        <div class="catalog">
            <p>用电客户编号</p>
            <input type="text" v-model="editEnterInfo.useElectroAccount" placeholder="输入用电客户编号">
        </div>
        <div class="catalog">
            <p>用水户口</p>
            <input type="text" v-model="editEnterInfo.useWaterAccount" placeholder="输入用水户口">
        </div>
        <div class="catalog">
            <p>主要结算银行</p>
            <input type="text" v-model="editEnterInfo.principalAccountBank" placeholder="输入主要结算银行">
        </div>
        <div class="catalog">
            <p>基本户开户行</p>
            <input type="text" v-model="editEnterInfo.basicAccountOpenBank" placeholder="输入基本户开户行">
        </div>
        <div class="catalog">
            <p>企业海关注册号</p>
            <input type="text" v-model="editEnterInfo.enterCustomsRegNum" placeholder="输入企业海关注册号">
        </div>
        <div class="catalog">
            <p>排放污染物许可证编号</p>
            <input type="text" v-model="editEnterInfo.pollDischPermitNum" placeholder="输入排放污染物许可证编号">
        </div>
        <div class="catalog">
            <p>排放污染物许可证编号</p>
            <input type="text" v-model="editEnterInfo.hazardousChemProduBusiLiceNo" placeholder="输入排放污染物许可证编号">
        </div>   
        <div class="catalog">
            <p>引导机构地市</p>
            <div class="select">
            <select v-model="guideCity">
                <option value="">请选择</option>
                <option value="450100">南宁市</option>
                <option value="450200">柳州市</option>
                <option value="450300">桂林市</option>
                <option value="450400">梧州市</option>
                <option value="450500">北海市</option>
                <option value="450600">防城港市</option>
                <option value="450700">钦州市</option>
                <option value="450800">贵港市</option>
                <option value="450900">玉林市</option>
                <option value="451000">百色市</option>
                <option value="451100">贺州市</option>
                <option value="451200">河池市</option>
                <option value="451300">来宾市</option>
                <option value="451400">崇左市</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>引导机构分行</p>
            <div class="select">
            <select v-model="guideBranch">
                <option value="">请选择</option>
                <option v-for="item in guideBranchList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>引导机构银行</p>
            <div class="select">
            <select v-model="editEnterInfo.guideAgencyCode">
                <option value="">请选择</option>
                <option v-for="item in guideAgencyList" :key="item" :value="item.orgCode">{{item.orgName}}</option>
            </select>
            </div>
        </div>
        <div class="catalog">
            <p>引导人</p>
            <input type="text" v-model="editEnterInfo.guidePerson" placeholder="输入引导人姓名">
        </div>   
    </div>
    <p class="title">企业经营信息</p>
    <div class="card">
        <div class="catalog">
            <p>上年销售收入（万元）</p>
            <input type="text" v-model="editEnterInfo.salesRevenueLastYear" placeholder="输入上年销售收入（万元）">
        </div>
        <div class="catalog">
            <p>上年利润（万元）</p>
            <input type="text" v-model="editEnterInfo.profitLastYear" placeholder="输入上年利润（万元）">
        </div>
        <div class="catalog">
            <p>上年资产额（万元）</p>
            <input type="text" v-model="editEnterInfo.assetsLastYear" placeholder="输入上年资产额（万元）">
        </div>   
        <div class="catalog">
            <p>上年负债额（万元）</p>
            <input type="text" v-model="editEnterInfo.liabilitiesLastYear" placeholder="输入上年负债额（万元）">
        </div>
    </div>
    <div class="flex-between">
        <p class="title">上游主要客户</p>
        <button class="add flex" @click="addClient(1)"><img src="../../assets/img/add.png">添加</button>        
    </div>    
    <div class="card" v-for="(item, index) in editEnterInfo.upMainCustomerList" :key="item">
        <div class="catalog">
            <p>规模</p>
            <div class="select">
            <select v-model="item.scale">
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
           </select>
            </div>
        </div>
        <div class="catalog">
            <p>主要客户名称</p>
            <input type="text" v-model="item.majorClient" placeholder="输入主要客户名称">
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <input type="text" v-model="item.addressAreaName" placeholder="输入国家或地市">
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <input type="text" v-model="item.purchaseAmtRatio" placeholder="输入采购额业务占比">
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <input type="text" v-model="item.cooperYears" placeholder="输入合作年限">
        </div>
        <div class="del-btn"><button class="del flex" @click="delClient(1,index)"><img src="../../assets/img/del_y.png">删除</button> </div>
    </div>
    <div class="flex-between">
        <p class="title">下游主要客户</p>
        <button class="add flex" @click="addClient(0)"><img src="../../assets/img/add.png">添加</button>        
    </div>    
    <div class="card" v-for="(item,index) in editEnterInfo.downMainCustomerList" :key="index">
        <div class="catalog">
            <p>规模</p>
            <div class="select">
            <select v-model="item.scale">
                <option value="0">微型</option>
                <option value="1">小型</option>
                <option value="2">中型</option>
                <option value="3">大型</option>
           </select>
            </div>
        </div>
       <div class="catalog">
            <p>主要客户名称</p>
            <input type="text" v-model="item.majorClient" placeholder="输入主要客户名称">
        </div>
        <div class="catalog">
            <p>国家或地市</p>
            <input type="text" v-model="item.addressAreaName" placeholder="输入国家或地市">
        </div>
        <div class="catalog">
            <p>采购额业务占比</p>
            <input type="text" v-model="item.purchaseAmtRatio" placeholder="输入采购额业务占比">
        </div>
        <div class="catalog">
            <p>合作年限</p>
            <input type="text" v-model="item.cooperYears" placeholder="输入合作年限">
        </div>
        <div class="del-btn"><button class="del flex" @click="delClient(0,index)"><img src="../../assets/img/del_y.png">删除</button> </div>
    </div>
    <div class="foot">
        <div class="edit" @click="saveEdit()">保存</div>
        <div class="edit" @click="goBack()">返回</div>
    </div>
</div>  
  </div>
</template>
<script>
import { ref,reactive,toRefs,toRaw,watch } from 'vue';
import { apiGetEnterInfo,extendJson,apiGetDistrict,apiBranchBanks,apiSubBranchBanks,apiEditEnterInfo } from '../../api/axios';
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
export default {
   setup() {
    let router = useRouter();
    const page = ref(false)
    const editEnterInfo = ref({
        provinceCode: '450000',
        enterName: '',
        cityCode: '',
        districtCode: '',
        upMainCustomerList: [],
        downMainCustomerList: [],
    })
    const state = reactive({
        // editEnterInfo: {
        //     enterName: '',
        //     cityCode: '450100',
        //     districtCode: '',
        //     upMainCustomerList: [],
        //     downMainCustomerList: [],
        // },
        district: [],
        guideCity: '',
        guideBranch: '',
        guideBranchList: [],
        guideAgencyList: [],
    })
    apiGetEnterInfo({}).then(res => {                         
        if(res.code == '1'){
            //   info.value = res.info;
            let info = extendJson(res.info.enterBaseInfo,res.info.enterRegisterInfo);
            info = extendJson(info,res.info.enterBusinessInfo);
            info = extendJson(toRaw(editEnterInfo.value),info);
            editEnterInfo.value = info;
            console.log(editEnterInfo.value,'返回的数据');
            page.value = true;
            getDistrict();
         }
         else {
           Toast(res.msg);
         }
    });
    const addClient = (i) => {
      if(i){
          editEnterInfo.value.upMainCustomerList.push({scale: '0',majorClient: '',addressAreaName: '',purchaseAmtRatio: '',cooperYears: ''});
      }else{
          editEnterInfo.value.downMainCustomerList.push({scale: '0',majorClient: '',addressAreaName: '',purchaseAmtRatio: '',cooperYears: ''});
      }      
    };
    const delClient = (i,index) => {
      if(i){
          editEnterInfo.value.upMainCustomerList.splice(index, 1);
      }else{
          editEnterInfo.value.downMainCustomerList.splice(index, 1);
      }      
    };
    const saveEdit = () => {
    //   console.log(toRaw(editEnterInfo.value),9999)
    //   apiEditEnterInfo
        apiEditEnterInfo(toRaw(editEnterInfo.value)).then(res => {
                console.log(res,'返回的数据')                   
                if(res.code == '1'){
                    Toast('保存成功');
                    router.go(-1);    
                }
                else {
                    Toast({
                        duration: 5000,
                        message: '保存修改失败'+res.msg,
                    });
                }
        });
    };
    const goBack = () => {
      router.go(-1);
    };
    //获取区县
        function getDistrict() {
            apiGetDistrict({cityCode: editEnterInfo.value.cityCode}).then(res => {
                // 获取数据成功后的其他操作
            console.log(res,'获取的数据')
            if(res.code === '1'){
                    state.district = res.info.districtList;
                    editEnterInfo.value.districtCode = state.district[0].cityCode;      
                }
                else {
                    Toast(res.msg);
                }           
            }).catch(err=>{
                console.log(err,'请求错误')
            }) 
        };
        
            //监听所属市变化
        watch(()=> [editEnterInfo.value.cityCode,state.guideCity,state.guideBranch], ([new1,new2,new3],[old1,old2,old3] ) => {
            if(new1 != old1) {
                getDistrict();
            }
            if(new2!=old2) {
                if(new2 != '') {                    
                    apiBranchBanks({cityCode: new2}).then(res => {                
                        if(res.code === '1'){
                            state.guideBranchList = res.info.bankList;
                            state.guideAgencyList.splice(0,state.guideAgencyList.length);
                            editEnterInfo.value.guideAgencyCode = '';
                            state.guideBranch = '';                            
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideBranchList.splice(0,state.guideBranchList.length);
                    state.guideBranch = '';
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    editEnterInfo.value.guideAgencyCode = '';
                }
                
            }
            if(new3 != old3) {
                if(new3 != '') {
                    apiSubBranchBanks({branchBankCode: new3}).then(res => {
                        if(res.code === '1'){
                            state.guideAgencyList = res.info.subBankList;
                            editEnterInfo.value.guideAgencyCode = '';  
                        }
                        else {
                            Toast(res.msg);
                        }           
                    })
                }else {
                    state.guideAgencyList.splice(0,state.guideAgencyList.length);
                    editEnterInfo.value.guideAgencyCode = '';  
                }
            }

        })

    return {
        page,
        editEnterInfo,
        ...toRefs(state),
        addClient,
        delClient,
        saveEdit,
        goBack
    };
  }
}
</script>
<style lang="less" scoped>
.productContent {
    padding-top: 12.5vw;
    p.title {
        font-size: 5vw;
        font-weight: 600;
        margin: 4vw;
    }
    .flex-between {
        padding: 0 4vw;
        p.title {
        margin: 0;
        }
        button.add {
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                border-radius: 4vw;
                border: 0;
                font-size: 4vw;
                line-height: 5vw;
                padding: 1vw 2vw;
                img {
                    width: 5vw;
                    height: auto;
                    margin-right: 1vw;
                }
            }
    }
    
    .card {
        padding: 2vw 4vw;
        .catalog {
            display: flex;
            align-items: center;
            p:first-child {
                width: 26vw;
                color: #666666;
                font-size: 4vw;
                padding: 3vw 0;
                .key {
                    color: #ff6600;
                }
            }
            p:last-child {
                width: 55vw;
                font-size: 4vw;
                padding-left: 4vw;
                // padding: 3vw 0;
            }
            select,input,textarea {
                background-color: #f4f8fe;
                border: 0;
                margin-left: 4vw;
                width: 55vw;
                border: 0;
                padding: 2vw;
                font-size: 4vw;
                border-radius: 1vw;
                box-sizing: border-box;
            }
            textarea {
                height: 15vw;
                resize: none;
            }

        }
        .del-btn {
            display: flex;
            justify-content: flex-end;
            button.del {
                color: #ff9900;
                border-radius: 4vw;
                border: 1px solid #ff9900;
                font-size: 4vw;
                line-height: 4vw;
                padding: 1vw 2vw;
                background-color: #fff;
                img {
                    width: 4.5vw;
                    height: auto;
                    margin-right: 1vw;
                }
            }
        }
        
    }
    .foot {
        display: flex;
        justify-content: center;
        padding-bottom: 4vw;
        .edit {
        background: linear-gradient(to right, #ff9900, #ff6700);
        color: #fff;
        font-size: 4vw;
        padding: 1vw 4vw;
        border-radius: 5vw;
        margin: 0 2vw;
    }
    }
    
    
}
</style>
